<template>
  <div class="password-input">
    <div
      class="box"
      v-for="i in 6"
      :key="i"
      :class="[
        password.length >= i ? 'active' : '',
        password.length >= i - 1 ? 'border' : ''
      ]"
    ></div>
    <input
      ref="passsword"
      id="passwordInput"
      type="number"
      v-model="password"
      class="password-input"
      @input="inputPassword"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: [Number, String]
  },
  data() {
    return {
      password: ''
    }
  },
  mounted() {
    let dom = document.getElementById('passwordInput')
    if (dom) {
      dom.focus()
    }
  },
  methods: {
    inputPassword(val) {
      if (this.password.length > 6) {
        this.password = this.password.substring(0, 6)
      }

      this.$emit('input', this.password)
    }
  }
}
</script>